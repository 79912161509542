import { useEffect, useState } from "react";
import { Pagination } from "antd";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useParams } from "react-router-dom";
import Slider from "../../Elements/Slider/Slider";
import classNames from "classnames/bind";
import styles from "./Home.module.scss";
import Card from "../../Elements/Card/Card";
import CardM from "../../Elements/Card_m/Card";
import iconHot from "../../../assets/images/hot.gif";
import Calculus1 from "../../../assets/images/Course/gt1.png";
import calculus2 from "../../../assets/images/Course/gt2.png";
import chemistry from "../../../assets/images/Course/hdc.png";
import physic1 from "../../../assets/images/Course/vl1.png";
import physic2 from "../../../assets/images/Course/n_vl2.png";
import xstk from "../../../assets/images/Course/xacsuatthongke.png";
import dstt from "../../../assets/images/Course/dstt.png";
import PPT from "../../../assets/images/Course/ppt.png";
//
import gkxstk from "../../../assets/images/Course/GKXSTK.png";
import gkdstt from "../../../assets/images/Course/GKDSTT.png";
import gkPPT from "../../../assets/images/Course/GKPPT.png";
import gkgt1 from "../../../assets/images/Course/GKGT1.png";
import gkvl1 from "../../../assets/images/Course/GTVL1.png";
//
import maytinh from "../../../assets/images/maytinh.png";
import baoduongcongnghiep from "../../../assets/images/baoduongcongnghiep.png";
import daukhi from "../../../assets/images/daukhi.png";
import cokhi from "../../../assets/images/cokhi.png";
import diendientu from "../../../assets/images/diendientu.png";
import hoahoc from "../../../assets/images/hoahoc.png";
import giaothong from "../../../assets/images/giaothong.png";
import moitruong from "../../../assets/images/moitruong.png";
import quanlycongnghiep from "../../../assets/images/quanlycongnghiep.png";
import khoahocungdung from "../../../assets/images/khoahocungdung.png";
import congnghe from "../../../assets/images/congnghe.png";
import xaydung from "../../../assets/images/xaydung.png";
//video
import img_1 from "../../../assets/images/Video/img1.png";
import img_2 from "../../../assets/images/Video/img2.png";
import matlab from "../../../assets/images/Video/matlab.png";
import img_4 from "../../../assets/images/Video/img4.png";
import img_5 from "../../../assets/images/Video/img5.png";
import img_6 from "../../../assets/images/Video/img6.png";
import geogebra from "../../../assets/images/Video/geogebra.png";
import casio from "../../../assets/images/Video/casio.png";
import Button from "../../Elements/Button/Button";
//
import daicuong from "../../../assets/images/newfeed/daicuong.png";
import dsttc1 from "../../../assets/images/newfeed/dsttc1.png";
import vl1 from "../../../assets/images/newfeed/vl1.png";
import gt1 from "../../../assets/images/newfeed/gt1.png";

const cx = classNames.bind(styles);
function Home() {
  useEffect(() => {
    document.title = "Trang chủ bách khoa cncp";
  });
  const CoursesDefault = [
    {
      id: "gt1",
      courseName: "Lớp Giải Tích 1 PRO",
      tutor: "A.Vương",
      price: 250,
      oldPrice: 450,
      lesson: 31,
      member: "1005+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: Calculus1,
      soon: false,
      discount:
        "(đăng ký trước 29/10/2024 tặng btl gt1, vl1, đstt,.. báo cáo tnvl,hóa đại cương,.. khóa học matlab)",
    },
    {
      id: "dstt",
      courseName: "Lớp Đại Số Tuyến Tính PRO",
      tutor: "A.Vương",
      price: 200,
      oldPrice: 400,
      lesson: 26,
      member: "485+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: dstt,
      soon: false,
      discount: "(đăng ký trước 29/10/2024 tặng btl đstt,.. báo cáo tnvl,...)",
    },
    {
      id: "vl1",
      courseName: "Lớp  vật lý 1",
      tutor: "A.Long",
      price: 300,
      oldPrice: 500,
      lesson: 22,
      member: "686+",
      linkRegister: "https://www.facebook.com/profile.php?id=100007913497459",
      img: physic1,
      soon: false,
      discount: "(đăng ký trước 29/10/2024 tặng btl mẫu vl1,... matlab,...)",
    },
    {
      id: "hdc",
      courseName: "Lớp Hóa Đại Cương PRO",
      tutor: "A.Vương",
      price: 200,
      oldPrice: 400,
      img: chemistry,
      lesson: 24,
      member: "436+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      soon: false,
      discount:
        "( đăng ký trước 29/10/2024 tặng btl gt1,vl1,.. báo cáo TN hóa,... )",
    },
  ];

  const CoursesDefaultK23 = [
    {
      id: "xstk",
      courseName: "Lớp Xác Suất Thống Kê A+",
      tutor: "A.Vương",
      price: 250,
      oldPrice: 450,
      lesson: 24,
      member: "401+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: xstk,
      soon: false,
      discount: "(tặng BTL XSTK, các môn chính trị và 2 khóa học chuyên ngành)",
    },
    {
      id: "ppt",
      courseName: "Lớp Phương Pháp Tính A+",
      tutor: "A.Vương",
      price: 200,
      lesson: 21,
      member: "156+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      oldPrice: 550,
      img: PPT,
      soon: false,
      discount: "(tặng BTL XSTK, các môn chính trị và 2 khóa học chuyên ngành)",
    },
    {
      id: "gt2",
      courseName: "Lớp Giải Tích 2",
      tutor: "A.Vương",
      price: 250,
      lesson: 36,
      member: "325+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      oldPrice: 400,
      img: calculus2,
      soon: false,
      discount: "(Sale áp dụng 15/9/2024-25/9/2024)",
    },
  ];
  const FaultyDefault = [
    { FaultyName: "Khoa Máy tính", id: "cse", img: maytinh },
    {
      FaultyName: "Khoa Bảo Dưỡng Công Nghiệp",
      id: "bdcn",
      img: baoduongcongnghiep,
    },

    {
      FaultyName: "Khoa Cơ Khí (cơ điện tử, dệt, logistic..)",
      id: "ck",
      img: cokhi,
    },
    { FaultyName: "Khoa Địa Chất và Dầu Khí", id: "dcvdk", img: daukhi },
    { FaultyName: "Khoa Điện-Điện Tử", id: "ddt", img: diendientu },
    {
      FaultyName: "Kỹ Thuật Giao Thông (ô tô, hàng không,...)",
      id: "ktgt",
      img: giaothong,
    },
    { FaultyName: "Khoa Kỹ Thuật hóa Học", id: "kthh", img: hoahoc },
    {
      FaultyName: "Khoa Môi Trường và Tài Nguyên",
      id: "mtvtn",
      img: moitruong,
    },
    {
      FaultyName: "Khoa Quản Lý Công Nghiệp",
      id: "qlcn",
      img: quanlycongnghiep,
    },
    {
      FaultyName: "Khoa Khoa Học Ứng Dụng (cơ kỹ thuật, vật lý kỹ thuật)",
      id: "khud",
      img: khoahocungdung,
    },
    { FaultyName: "Khoa Khoa Công Nghệ Vật Liệu", id: "cnvl", img: congnghe },
    {
      FaultyName: "Khoa Khoa Kỹ Thuật Xây Dựng (kiến trúc...)",
      id: "xd",
      img: xaydung,
    },
  ];

  const VideoDefault = [
    {
      name: "Video giải đề minh họa giải tích 1 HK231 ",
      id: "cse",
      img: daicuong,
      path: "https://drive.google.com/open?id=1ClRNxq8dNXeMq5j3_8Xc9ZgBNhXK4-0y&usp=drive_copy",
    },
    {
      name: "[Video Đại số tuyến tính]: Chương 1 Ma trận",
      id: "cse",
      img: dsttc1,
      path: "https://www.youtube.com/watch?v=GR077ZJiZPI&list=PL8RXTGoT1e8NUhRYz1RVQ1hhHxUil7Otn&index=2",
    },
    {
      name: "[Video Giải tích 1]: Chương 1 Lim và các phương pháp tính lim",
      id: "cse",
      img: gt1,
      path: "https://www.youtube.com/watch?v=O7WrQoLtoEw",
    },
    {
      name: "[Video Vật Lý 1]: Chương 1 Chuyển động thẳng đều, biếm đổi đều",
      id: "cse",
      img: vl1,
      path: "https://www.youtube.com/watch?v=V0Ro5QL9maU",
    },
    {
      name: "Review các môn đại cương Bách Khoa",
      img: img_5,
      path: "https://www.youtube.com/watch?v=X5bQszvhz5U",
    },
    {
      name: "Casio Định thức có tham số m",
      img: casio,
      path: "https://www.youtube.com/watch?v=2Mdl1xAxmkQ&list=PL8RXTGoT1e8NUhRYz1RVQ1hhHxUil7Otn",
    },
    {
      name: "Giải đề giữa kì giải tích 2",
      img: img_1,
      path: "https://www.youtube.com/watch?v=1u0zAyM8Cxo&list=PL8RXTGoT1e8PwIsc4dsaEQK8CQP8YfCVC",
    },
    {
      name: "Geogebra",
      img: geogebra,
      path: "https://www.youtube.com/watch?v=zXE9Q9EUXKg&list=PL8RXTGoT1e8O36qrHCCeqrdIXMCw652MB",
    },
    {
      name: "Giải đề giữa kì vật lý 2",
      img: img_2,
      path: "https://www.youtube.com/watch?v=M8o2Px_Q4BE&list=PL8RXTGoT1e8PhYSNJwYDzVX07S4C4OXuf",
    },
    {
      name: "Buổi 1: Xác suất thống kê",
      img: img_4,
      path: "https://www.youtube.com/watch?v=Ha9RhOGlJqU&lc=Ugz2iPEoHAZbPx-Xo3Z4AaABAg",
    },

    {
      name: "Tìm giá trị lớn nhất, nhỏ nhất trong miền 3D",
      img: matlab,
      path: "/",
    },

    { name: "Mẹo vặt trong giải tích 2", img: img_6, path: "/" },
  ];
  const [Courses, setCourses] = useState(null);
  const [Faultys, setFaultys] = useState(null);
  const [Videos, setVideos] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    setCourses(CoursesDefault);
    setFaultys(FaultyDefault);
    setVideos(VideoDefault);

    return () => {};
  }, []);
  useEffect(() => {
    id
      ? window.scrollTo({ top: id, behavior: "smooth" })
      : window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleResize = () => {
    window.innerWidth < 768
      ? (() => {
          setFaultys(() => {
            return FaultyDefault.slice(0, 4);
          });
          setVideos(() => {
            return VideoDefault.slice(0, 4);
          });
        })()
      : (() => {
          setFaultys(() => {
            return FaultyDefault;
          });
          setVideos(() => {
            return VideoDefault;
          });
        })();
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div className={cx("slider")}>
        <Slider></Slider>
      </div>
      <div className={cx("wrapper")}>
        <div className={cx("course-heading")}>
          <h2>
            <span>Khóa Học Cuối kì K24 PRO</span>
            <img src={iconHot}></img>
            <span className={cx("chuamo")}>Mở nhận học viên từ 20/10/2024</span>
          </h2>
        </div>
        <div className={cx("course-list")}>
          {Courses?.map((course, index) => {
            console.log(course);
            return (
              <div>
                <CardM
                  link={`/course/${course.id}`}
                  lesson={course.lesson}
                  linkRegister={course.linkRegister}
                  member={course.member}
                  key={index}
                  title={course.courseName}
                  price={course.price}
                  oldPrice={course.oldPrice}
                  image={course.img}
                  tutor={course.tutor}
                  soon={course.soon}
                  discountText={course.discount}
                ></CardM>
              </div>
            );
          })}
        </div>
        <div className={cx("course-heading")}>
          <h2>
            <span>Khóa Học Cuối Kì Cho Sinh Viên K23</span>
            <img src={iconHot}></img>
            <span className={cx("chuamo")}> Mở nhận học viên</span>
          </h2>
        </div>
        <div className={cx("course-list")}>
          {CoursesDefaultK23?.map((course, index) => {
            console.log(course);
            return (
              <div>
                <Card
                  link={`/course/${course.id}`}
                  key={index}
                  lesson={course.lesson}
                  linkRegister={course.linkRegister}
                  member={course.member}
                  title={course.courseName}
                  price={course.price}
                  oldPrice={course.oldPrice}
                  image={course.img}
                  tutor={course.tutor}
                  soon={course.soon}
                  discountText={course.discount}
                ></Card>
              </div>
            );
          })}
        </div>
        <div className={cx("course-heading")}>
          <h2>
            <span>Tài Liệu Các Khoa</span>
          </h2>
        </div>
        <div className={cx("course-list")}>
          {Faultys?.map((Faulty, index) => {
            return (
              <div>
                <Card
                  link={`/learning/${Faulty.id}`}
                  document={true}
                  key={index}
                  title={Faulty.FaultyName}
                  image={Faulty.img}
                ></Card>
              </div>
            );
          })}
        </div>
        <div className={cx("pagination")}>
          <Pagination
            onChange={(page) => {
              setFaultys(FaultyDefault.slice(page * 4 - 4, page * 4));
            }}
            defaultCurrent={1}
            total={30}
          ></Pagination>
        </div>
        <div className={cx("course-heading")}>
          <h2>
            <span>Video Hay Nên Xem</span>
          </h2>
        </div>
        <div className={cx("course-list")}>
          {Videos?.map((item, index) => {
            return (
              <div>
                <Card
                  blank={true}
                  link={item.path}
                  document={true}
                  video={true}
                  key={index}
                  title={item.name}
                  image={item.img}
                ></Card>
              </div>
            );
          })}
        </div>
        <div className={cx("pagination", { desktop: true })}>
          <Pagination
            onChange={(page) => {
              setVideos(VideoDefault?.slice(page * 4 - 4, page * 4));
            }}
            defaultCurrent={1}
            total={30}
          ></Pagination>
        </div>
        <div className={cx("link-intro")}>
          <h3>CÁC CHỦ ĐỀ ĐÁNG CHÚ Ý</h3>
          <div>
            <Button
              onclick={() => {
                window.scrollTo({ top: 250, behavior: "smooth" });
              }}
              pt5
              text={"Thông Tin Lớp Đại Cương"}
            ></Button>
            <Button
              link={"https://zalo.me/g/mwduif387"}
              blank
              pt5
              text={"Nhóm Zalo"}
            ></Button>
            <Button link={"/pt5"} pt5 text={"Tính điểm phương thức 5"}></Button>
            <Button link={"/course/xstk"} pt5 text={"Sự kiện hot"}></Button>
            <Button
              link={"/course/xstk"}
              pt5
              text={"Lớp xác xuất thống kê mới mở"}
            ></Button>
            <Button
              link={"/document/290"}
              pt5
              text={"Khóa học miễn phí"}
            ></Button>
            <Button
              link={"https://www.youtube.com/@cncp246"}
              pt5
              blank={true}
              text={"Kênh youtube"}
            ></Button>
            <Button
              link={"https://www.facebook.com/groups/495364634751426"}
              pt5
              text={"Nhóm facebook HCMUT-CNCP"}
            ></Button>
            <Button
              link={"/document"}
              pt5
              text={"Ôn tập chứng chứng chỉ tin học"}
            ></Button>

            <Button
              link={"/document"}
              pt5
              text={"Tài liệu anh văn đầu vào"}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
