import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCameraRetro,
  faCheck,
  faMinus,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faWordpress } from "@fortawesome/free-brands-svg-icons";
import styles from "./Assignment.module.scss";
import subject from "../../../assets/images/Course/gt1.png";
import Subject from "../../Elements/Subject/Subject";
import Button from "../../Elements/Button/Button";
import Card from "../../Elements/Card/Card";
//video
import img_1 from "../../../assets/images/Video/img1.png";
import img_2 from "../../../assets/images/Video/img2.png";
import matlab from "../../../assets/images/Video/matlab.png";
import img_4 from "../../../assets/images/Video/img4.png";
import img_5 from "../../../assets/images/Video/img5.png";
import img_6 from "../../../assets/images/Video/img6.png";
import geogebra from "../../../assets/images/Video/geogebra.png";
import casio from "../../../assets/images/Video/casio.png";
import daicuong from "../../../assets/images/newfeed/daicuong.png";
import dsttc1 from "../../../assets/images/newfeed/dsttc1.png";
import vl1 from "../../../assets/images/newfeed/vl1.png";
import gt1 from "../../../assets/images/newfeed/gt1.png";
import { Pagination } from "antd";
const cx = classNames.bind(styles);

function Assignment() {
  const VideoDefault = [
    {
      name: "Review các môn đại cương",
      id: "cse",
      img: daicuong,
      path: "https://www.youtube.com/watch?v=X5bQszvhz5U",
    },
    {
      name: "[Video Đại số tuyến tính]: Chương 1 Ma trận",
      id: "cse",
      img: dsttc1,
      path: "https://www.youtube.com/watch?v=GR077ZJiZPI&list=PL8RXTGoT1e8NUhRYz1RVQ1hhHxUil7Otn&index=2",
    },
    {
      name: "[Video Giải tích 1]: Chương 1 Lim và các phương pháp tính lim",
      id: "cse",
      img: gt1,
      path: "https://www.youtube.com/watch?v=O7WrQoLtoEw",
    },
    {
      name: "[Video Vật Lý 1]: Chương 1 Chuyển động thẳng đều, biếm đổi đều",
      id: "cse",
      img: vl1,
      path: "https://www.youtube.com/watch?v=V0Ro5QL9maU",
    },
    {
      name: "Review các môn đại cương Bách Khoa",
      img: img_5,
      path: "https://www.youtube.com/watch?v=X5bQszvhz5U",
    },
    {
      name: "Casio Định thức có tham số m",
      img: casio,
      path: "https://www.youtube.com/watch?v=2Mdl1xAxmkQ&list=PL8RXTGoT1e8NUhRYz1RVQ1hhHxUil7Otn",
    },
    {
      name: "Giải đề giữa kì giải tích 2",
      img: img_1,
      path: "https://www.youtube.com/watch?v=1u0zAyM8Cxo&list=PL8RXTGoT1e8PwIsc4dsaEQK8CQP8YfCVC",
    },
    {
      name: "Geogebra",
      img: geogebra,
      path: "https://www.youtube.com/watch?v=zXE9Q9EUXKg&list=PL8RXTGoT1e8O36qrHCCeqrdIXMCw652MB",
    },
    {
      name: "Giải đề giữa kì vật lý 2",
      img: img_2,
      path: "https://www.youtube.com/watch?v=M8o2Px_Q4BE&list=PL8RXTGoT1e8PhYSNJwYDzVX07S4C4OXuf",
    },
    {
      name: "Buổi 1: Xác suất thống kê",
      img: img_4,
      path: "https://www.youtube.com/watch?v=Ha9RhOGlJqU&lc=Ugz2iPEoHAZbPx-Xo3Z4AaABAg",
    },

    {
      name: "Tìm giá trị lớn nhất, nhỏ nhất trong miền 3D",
      img: matlab,
      path: "/",
    },

    { name: "Mẹo vặt trong giải tích 2", img: img_6, path: "/" },
  ];
  const DataAssignmentBasic = [
    {
      name: "Giải tích 1",
      note: "Đăng kí lớp giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      fee: "Trao đổi",

      assignments: [
        {
          name: "Toàn bộ BTL GT1 anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Vật lý 1",
      fee: "Trao đổi",
      note: "Đăng kí lớp vật lý 1, giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "Xác định bán kính quỹ đạo của vật tại vị trí chạm đất và vẽ quỹ đạo của vật",
          price: 0,
          path: null,
        },
        {
          name: "VẼ QUỸ ĐẠO NÉM XIÊN TRONG TRƯỜNG TRỌNG LỰC BỎ QUA LỰC CẢN VÀ XÁC ĐỊNH MỘT SỐ THÔNG TIN LIÊN QUAN",
          price: 0,
          path: null,
        },
        {
          name: "XÁC ĐỊNH QUỸ ĐẠO CHUYỂN ĐỘNG NÉM XIÊN TRONG TRỌNG TRƯỜNG CÓ LỰC CẢN MÔI TRƯỜNG, bài 12-17",
          price: 0,
          path: null,
        },
        {
          name: "Xác định quỹ đạo của vật",
          price: 0,
          path: null,
        },
        {
          name: "XÁC ĐỊNH LỰC TƯƠNG TÁC TĨNH ĐIỆN GIỮA CÁC ĐIỆN TÍCH ĐIỂM TRONG CHÂN KHÔNG",
          price: 0,
          path: null,
        },
        {
          name: "Vẽ quỹ đạo của vật",
          price: 0,
          path: null,
        },
        {
          name: "Vẽ quỹ đạo của vật khi có phương trình chuyển động",
          price: 0,
          path: null,
        },
        {
          name: "VẼ QUỸ ĐẠO VÀ XÁC ĐỊNH VECTO MOMEN ĐỘNG LƯỢNG CỦA  CHUYỂN ĐỘNG VỚI PHƯƠNG TRÌNH CHO BỞI X(T) VÀ Y(T)",
          price: 0,
          path: null,
        },
        {
          name: "Vẽ quỹ đạo và xác định vectơ momen động lượng của chuyển động với phương trình cho bởi x(t) và y(t)",
          price: 0,
          path: null,
        },
        {
          name: "XÁC ĐỊNH QUỸ ĐẠO CHUYỂN ĐỘNG NÉM  XIÊN TRONG TRỌNG TRƯỜNG CÓ LỰC CẢN MÔI TRƯỜNG",
          price: 0,
          path: null,
        },
        {
          name: "Xác định quỹ đạo của vật",
          price: 0,
          path: null,
        },
        {
          name: "Xác định phương trình chuyển động của tên lửa”",
          price: 0,
          path: null,
        },
        {
          name: "Vẽ quỹ đạo chuyển động ném xiên trong trọng lực bỏ qua lực cản và xác định một vài thông số liên",
          price: 0,
          path: null,
        },
        {
          name: "XÁC ĐỊNH QUỸ ĐẠO CHUYỂN ĐỘNG NÉM XIÊN TRONG TRỌNG TRƯỜNG CÓ LỰC CẢN BẰNG MATHLAB",
          price: 0,
          path: null,
        },
        {
          name: "Ném xiên",
          price: 0,
          path: null,
        },
        {
          name: "Vẽ mặt điện thế và vectơ cường độ điện	trường trong không gian Oxy",
          price: 0,
          path: null,
        },
        {
          name: "Xác định quỹ đạo của vật bài 1-7",
          price: 0,
          path: null,
        },
        {
          name: "Xác định quỹ đạo chuyển động ném xiên trong trọng trường có lực cản môi trường",
          price: 0,
          path: null,
        },
        {
          name: "VẼ QUỸ ĐẠO CỦA ELECTRON TRONG ĐIỆN TRƯỜNG TĨNH",
          price: 0,
          path: null,
        },
        {
          name: " Xác định lực tương tác tĩnh điện giữa các điện tích điểm trong chân không.",
          price: 0,
          path: null,
        },
        {
          name: "XÁC ĐỊNH QUỸ ĐẠO CỦA VẬT KHI CÓ VECTƠ BÁN KÍNH",
          price: 0,
          path: null,
        },
        {
          name: "ĐỘNG NĂNG VÀ THẾ NĂNG CỦA MỘT CHẤT ĐIỂM DƯỚI TÁC DỤNG CỦA LỰC THẾ",
          price: 0,
          path: null,
        },
        {
          name: "STUDYING KINETIC AND POTENTIAL  ENERGY OF A PARTICLE MOVING UNDER  INFLUENCE OF CONSERVATIVE FORCE",
          price: 0,
          path: null,
        },
        {
          name: "Động năng và thế năng của một chất điểm",
          price: 0,
          path: null,
        },
        {
          name: "PHÂN BỐ VẬN TỐC MAXWELL",
          price: 0,
          path: null,
        },
        {
          name: "",
          price: 0,
          path: null,
        },
        {
          name: "",
          price: 0,
          path: null,
        },
        {
          name: "",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Xác suất thống kê",
      note: "Đăng kí lớp xác suất thống kê mới được nhận!",
      fee: "Trao đổi",
      assignments: [
        {
          name: "Khoa điện",
          price: 0,
          path: null,
        },
        {
          name: "Khoa cơ khí",
          price: 0,
          path: null,
        },
        {
          name: "Khoa máy tính",
          price: 0,
          path: null,
        },
        {
          name: "Khoa cơ khí, cơ điện tử",
          price: 0,
          path: null,
        },
        {
          name: "Khoa hóa",
          price: 0,
          path: null,
        },
        {
          name: "Khoa khoa học úng dụng",
          price: 0,
          path: null,
        },
        {
          name: "Khoa xây dựng",
          price: 0,
          path: null,
        },
        {
          name: "Khoa môi trường",
          price: 0,
          path: "Khoa giao thông (ô tô, tàu thủy, hàng không,..)",
        },
        {
          name: "Khoa Công nghệ vật liệu",
          price: 0,
          path: null,
        },
        {
          name: "Khoa bảo dưỡng công nghiệp",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Đại số tuyến tính",
      fee: "Trao đổi",
      note: "Đăng kí lớp giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "Đề tài 1: Nêu các ứng dụng hình học của định thức",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 2: PHÂN TÍCH A = LU VÀ PHÂN TÍCH PA = PLU",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 3: PHÂN TÍCH A = QR BẰNG PHƯƠNG PHÁP  GRAM-SCHMIDT",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 4: PHÂN TÍCH A = QR BẰNG PHÉP BIẾN ĐỔI HOUSEHOLDER",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 5: PHÂN TÍCH A=QR BẰNG PHÉP QUAY GIVEN",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 6: Hamming",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 7: SVD nén dữ liệu",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 8: SVD khủ nhiễm âm thanh",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 9: Ứng dụng của phân tích SVD vào hệ thống gợi ý trong học máy (meachine Learning",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 10: SVD khử nhiễm hình ảnh",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 11: PHÂN TÍCH THÀNH PHẦN CHÍNH PCA GIAM CHIEU DAI DU LIEU",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 12: PCA ứng dụng hồi quy tuyến tính",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 13: Ứng dụng của phân tích PCA vào nhận dạng khuôn mặt ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 14: Mô hình Markov và ứng dụng",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 15: MÔ HÌNH MARKOV TRONG THUẬT  TOÁN GOOGLE PAGERANK",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 16: Mô hình leiles",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 17: Giới thiệu mô hình Input-Output-Leontief ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 18: Bình phương cực tiểu",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 19: “ỨNG DỤNG FOURIER VÀO  KHỬ NHIỄU ÂM THANH”",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 20: Fourier trong nén dữ liệu",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 21: Floyd Warshall, úng dụng tìm đường đi ngắn nhất ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 22: Hill trong bài toán mã hóa",
          price: 0,
          path: null,
        },

        {
          name: "Đề tài 23: Phép biến đổi HAAR để nén dữ liệu",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 24: Ma trận Stochatic",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 25: Ứng dụng đại số tuyến tính trong di truyền",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 26: Quy hoạch tuyến tính với bài toán vận tải",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 27: Hồi quy tuyến tính",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 28: Phương pháp lặp Jacobi để giải hệ phương trình tuyến tính .Nêu điều kiện hội tụ của phương pháp",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 29: Phân tích LU, PLU và ứng dụng",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 30: PHÂN TÍCH QR BẰNG 3 PHƯƠNG PHÁP: GRAM-SCHMIDT, HOUSEHOLDER, GIVENS",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 31: Phương pháp nhân tử LU ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 32: Phương pháp số",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 33: PHƯƠNG PHÁP STEEPEST DESCENT",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 34: Số phức",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 35: STEEPEST DESCENT METHOD",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 36: SỬ DỤNG PHƯƠNG PHÁP LẶP CONJUGATE GRADIENT ĐỂ GIẢI HỆ PHƯƠNG TRÌNH TUYẾN TÍNH",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 37: Ử DỤNG PHƯƠNG PHÁP LẶP GRADIENT ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 38: TÌM HIỂU VỀ PHƯƠNG PHÁP SUCCESSIVE OVER-RELAXATION (SOR)",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 39: Xử lí ảnh",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 40: Chu trình Euler và chu trình Hamilton",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 41: PHÉP QUAY GIVEN TRONG XỬ LÝ HÌNH ẢNH TRONG KHÔNG GIAN ĐA CHIỀU",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 42: Dự đoán, vectơ riêng, phân tích thành phần chính và  thuật toán nhận diện khuôn mặt. ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 43: USE ORTHOGONAL MATRICES FOR THE ROTATION OF  3D OBJECTS.",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 44: INTERPOLATION, EXTRAPOLATION  AND CLIMATE CHANGE ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 45: PHÉP NHÂN MA TRẬN TRONG MẠNG NEURAL LAN  TRUYỀN TIẾN ",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 46: MA TRẬN TRỰC GIAO VÀ ĐỒ HỌA 3D",
          price: 0,
          path: null,
        },
        {
          name: "Đề tài 47: MatrixeigenvaluesandtheGoogle’sPageRank  algorithm",
          price: 0,
          path: null,
        },
        {
          name: " Đề tài 48: PHÁT HIỆN HÌNH ẢNH CẮT  GHÉP BẰNG SVD",
          price: 0,
          path: null,
        },
        {
          name: "Một số đề tài OISP",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Hóa đại cương",
      fee: "Trao đổi",
      note: "Đăng kí lớp giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "Báo cáo thí nghiệm hóa đại cương",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Giải tích 2",
      note: "Đăng kí lớp giải tích 2 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      fee: "Trao đổi",
      assignments: [
        {
          name: "Xem toàn bộ BTL anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Vật lý 2",
      fee: "Trao đổi",
      note: "Đăng kí lớp vật lý 2 hoặc giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "BỨC XẠ NHIỆT VÀ ỨNG DỤNG",
          price: 0,
          path: null,
        },
        {
          name: "Hiệu ứng Hall và ứng dụng",
          price: 0,
          path: null,
        },
        {
          name: " CÁC LOẠI MÀN HÌNH CRT,LED,LCD, Plasma",
          price: 0,
          path: null,
        },
        {
          name: "CÁC THẾ HỆ 1G, 2G, 3G, 4G, 5G",
          price: 0,
          path: null,
        },
        {
          name: "Chấm lượng tử và ứng dụng",
          price: 0,
          path: null,
        },
        {
          name: " Điôt phát quang(LED)",
          price: 0,
          path: null,
        },
        {
          name: "Hạt Higgs",
          price: 0,
          path: null,
        },
        {
          name: "HIỆU ỨNG ZEEMAN",
          price: 0,
          path: null,
        },
        {
          name: "KÍNH VIỄN VỌNG",
          price: 0,
          path: null,
        },
        {
          name: "Tia Laser và ứng dụng",
          price: 0,
          path: null,
        },
        {
          name: "LED VÀ ỨNG DỤNG CỦA LED",
          price: 0,
          path: null,
        },
        {
          name: "Lưu trữ đĩa quang học, đĩa từ và lazer.",
          price: 0,
          path: null,
        },
        {
          name: "Các hệ quả thú vị của thuyết tương đối hẹp và rộng",
          price: 0,
          path: null,
        },
        {
          name: "CÔNG NGHỆ INVERTER",
          price: 0,
          path: null,
        },
        {
          name: "GIAO THOA KẾ MICHELSON ",
          price: 0,
          path: null,
        },
        {
          name: "HIỆU ỨNG DOPPLER VÀ CÁC ỨNG DỤNG ",
          price: 0,
          path: null,
        },
        {
          name: "Lô đen ",
          price: 0,
          path: null,
        },
        {
          name: "Radar",
          price: 0,
          path: null,
        },
        {
          name: "Tìm hiểu về vụ nổ lớn Big Bang",
          price: 0,
          path: null,
        },
        {
          name: "CÁC ỨNG DỤNG CỦA CƠ HỌC LƯỢNG TỬ: MÁY TÍNH LƯỢNG TỬ",
          price: 0,
          path: null,
        },
        {
          name: "SÓNG HẤP DẪN",
          price: 0,
          path: null,
        },
        {
          name: "SỰ TẠO THÀNH SÓNG ĐIỆN TỪ. CÁC ỨNG DỤNG CỦA SÓNG ĐIỆN TỪ",
          price: 0,
          path: null,
        },
        {
          name: "TÀU ĐỆM TỪ TRƯỜNG",
          price: 0,
          path: null,
        },
        {
          name: " THẾ GIỚI HẠT CƠ BẢN. PHẢN VẬT CHẤT",
          price: 0,
          path: null,
        },
        {
          name: "Ứng dụng của kỹ thuật hạt nhân trong y tế",
          price: 0,
          path: null,
        },

        {
          name: "VŨ KHÍ NGUYÊN TỬ’",
          price: 0,
          path: null,
        },
        {
          name: "Chấm lượng tử và ứng dụng",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Phương pháp tính",
      fee: "Trao đổi",
      note: "Đăng kí lớp giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "Xem toàn bộ BTL anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Thí nghiệm vật lý",
      fee: "Trao đổi",
      note: "Đăng kí lớp giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "Full bộ báo cáo và chuẩn bị TNVL",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Vật lý bán dẫn",
      fee: "Trao đổi",
      note: "Đăng kí lớp giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "Xem toàn bộ BTL anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Kỹ thuật lập trình",
      fee: "Trao đổi",
      note: "Đăng kí lớp giải tích 1 hoặc hóa hoặc đại số tuyến tính mới được nhận!",
      assignments: [
        {
          name: "Xem toàn bộ BTL anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
  ];
  const DataAssignmentSpecialized = [
    {
      name: "Kỹ thuật lập trình",
      note: "Để tránh việc tài liệu bị lạm dụng nên các bạn ib với anh để nhận trực tiếp nha!",
      assignments: [
        {
          name: "Xem toàn bộ BTL anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Chi tiết máy",

      assignments: [
        {
          name: "Xem toàn bộ BTL anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Phương pháp số",

      assignments: [
        {
          name: "Xem toàn bộ BTL anh đã mua",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Báo cáo thực tập trắc địa",

      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Đồ án nền móng",

      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Công nghệ phần mền",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Điện tử công suất ứng dụng",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Đồ án công nghệ phần mền",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Đo lường máy tính",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Hệ cơ sở dữ liệu",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Kỹ thuật Robot",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Lập trình nâng cao",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Lập trình web",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Thí nghiệm lập trình web",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Lý thuyết điều kiển nâng cao",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Mạng máy tính",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Scada",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Sức bền vật liệu",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Thủy khí",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Vật liệu xây dựng",
      assignments: [
        {
          name: "Xem tài liệu",
          price: 0,
          path: null,
        },
      ],
    },
  ];
  const DataAssignmentSocial = [
    {
      name: "Pháp luật đại cương",

      assignments: [
        {
          name: "NHẬN DIỆN HỢP ĐỒNG LAO ĐỘNG THEO BỘ LUẬT LAO ĐỘNG NĂM 2019",
          price: 0,
          path: null,
        },
        {
          name: "BÀN VỀ THỪA KẾ THẾ VỊ THEO QUY ĐỊNH CỦA BỘ LUẬT DÂN SỰ NĂM 2015",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA CÔNG NGHỆ SMART HOME TRONG QUÁ TRÌNH CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "DIỆN VÀ HÀNG THỪA KẾ THEO QUY ĐỊNH CỦA BỘ LUẬN DÂN SỰ NĂM 2015",
          price: 0,
          path: null,
        },
        {
          name: "PHÁP NHÂN, CHỦ THỂ CỦA QUAN HỆ PHÁP LUẬT DÂN SỰ",
          price: 0,
          path: null,
        },
        {
          name: "BÀN VỀ VẤN ĐỀ SA THẢI NGƯỜI LAO ĐỘNG THEO BỘ LUẬT NĂM 2019",
          price: 0,
          path: null,
        },
        {
          name: "BÀN VỀ NGƯỜI THỪA KẾ KHÔNG PHỤ THUỘC VÀO NỘI DUNG DI CHÚC THEO BỘ LUẬT DÂN SỰ NĂM 2015",
          price: 0,
          path: null,
        },
        {
          name: "CHẾ ĐỊNH BẢO VỆ NGƯỜI SỬ DỤNG LAO ĐỘNG TRONG TÌNH HÌNH DỊCH COVID-19 THEO QUY ĐỊNH PHÁP LUẬT LAO ĐỘNG VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "HỢP ĐỒNG LAO ĐỘNG CỦA NGƯỜI SỬ DỤNG LAO ĐỘNG THEO BỘ LUẬT LAO ĐỘNG NĂM 2019",
          price: 0,
          path: null,
        },
        {
          name: "QUYỀN ĐƠN PHƯƠNG CHẤM DỨT HỢP ĐỒNG LAO ĐỘNG CỦA NGƯỜI SỬ DỤNG LAO ĐỘNG THEO BỘ LUẬT LAO ĐỘNG NĂM 2019",
          price: 0,
          path: null,
        },
        {
          name: "HIỆU LỰC PHÁP LUẬT CỦA GIAO DỊCH DÂN SỰ DO “NGƯỜI YẾU THẾ” XÁC LẬP, THỰC HIỆN THEO BỘ LUẬT DÂN SỰ NĂM 2015",
          price: 0,
          path: null,
        },
        {
          name: "DIỆN VÀ HÀNG THỪA KẾ THEO QUY ĐỊNH CỦA BỘ LUẬT DÂN SƯ 2015",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Triết học Mac-Lenin",
      assignments: [
        {
          name: "Cặp phạm trù nguyên nhân – kết quả của phép biện chứng duy vật. Vận dụng cặp phạm trù này trong việc tìm hiểu tình trạng kẹt xe ở thành phố Hồ Chí Minh hiện nay",
          price: 0,
          path: null,
        },
        {
          name: "PHÁT HUY TÍNH SÁNG TẠO CỦA Ý THỨC TRONG HOẠT ĐỘNG NGHIÊN CỨU KHOA HỌC CỦA SINH VIÊN KHOA CƠ KHÍ TRƯỜNG ĐẠI HỌC BÁCH KHOA THÀNH PHỐ HỒ CHÍ MINH",
          price: 0,
          path: null,
        },
        {
          name: "VẬN DỤNG CẶP PHẠM TRÙ KHẢ NĂNG VÀ HIỆN THỰC ĐỂ PHÁT TRIỂN THẾ HỆ MĂNG NON CỦA ĐẤT NƯỚC",
          price: 0,
          path: null,
        },
        {
          name: "NHỮNG SẢN PHẨM MỚI CỦA SINH VIÊN BÁCH KHOA TRONG PHÒNG CHỐNG DỊCH BỆNH COVID19 DỰA TRÊN TÍNH SÁNG TẠO CỦA Ý  THỨC",
          price: 0,
          path: null,
        },
        {
          name: "QUY LUẬT BIỆN CHỨNG GIỮA CƠ SỞ HẠ TẦNG VÀ KIẾN TRÚC THƯỢNG TẦNG. XÂY DỰNG NHÀ NƯỚC PHÁP QUYỀN XÃ HỘI CHỦ",
          price: 0,
          path: null,
        },
        {
          name: "VẬN DỤNG QUY LUẬT VỀ SỰ PHÙ HỢP CỦA QUAN HỆ SẢN XUẤT VỚI TRÌNH ĐỘ PHÁT TRIỂN CỦA LỰC LƯỢNG SẢN XUẤT VÀO VIỆC XÂY DỰNG NỀN KINH TẾ THỊ TRƯỜNG ĐỊNH HƯỚNG XÃ HỘI CHỦ NGHĨA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "NGUYÊN LÝ MỐI LIÊN HỆ PHỔ BIẾN VỚI HOẠT ĐỘNG RÈN LUYỆN KỸ NĂNG MỀM CỦA SINH VIÊN TRƯỜNG ĐẠI HỌC BÁCH KHOA",
          price: 0,
          path: null,
        },
        {
          name: "RÈN LUYỆN KỸ NĂNG LÀM VIỆC NHÓM CHO SINH VIÊN KHÓA K20 TRƯỜNG ĐẠI HỌC BÁCH KHOA TRÊN CƠ SỞ LÝ LUẬN NGUYÊN LÝ MỐI LIÊN HỆ PHỔ BIẾN",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ GIA ĐÌNH TRONG THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. THỰC TRẠNG VÀ GIẢI PHÁP PHÒNG, CHỐNG BẠO LỰC GIA ĐÌNH Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "Quy luật về mối quan hệ biện chứng giữ cơ sở hạ tầng và việc vận dụng mối quan hệ này trong sự nghiệp đổi mới đi lên từ XHCN ở VN hiện nay  ",
          price: 0,
          path: null,
        },
        {
          name: "BIỆN CHỨNG GIỮA TỒN TẠI XÃ HỘI VỚI Ý THỨC XÃ HỘI. VẬN DỤNG MỐI QUAN HỆ NÀY TRONG VIỆC TÌM HIỂU Ý THỨC CHẤP HÀNH GIAO THÔNG CỦA NGƯỜI DÂN VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUY LUẬT THỐNG NHẤT VÀ ĐẤU TRANH GIỮA CÁC MẶT ĐỐI LẬP. VIỆC VẬN DỤNG QUY LUẬT NÀY TRONG HOẠT ĐỘNG GIẢI QUYẾT MÂU THUẪN GIỮA SẢN XUẤT VÀ TIÊU DÙNG KHẨU TRANG CỦANGƯỜI DÂN VIỆT NAM TRONG THỜI KÌ COVID-19 (2019-2021)",
          price: 0,
          path: null,
        },
        {
          name: "VẬN DỤNG NGUYÊN TẮC THỐNG NHẤT GIỮA LÝ LUẬN VÀ THỰC TIỄN VÀO QUÁ TRÌNH ĐỔI MỚI Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "VẬN DỤNG NGUYÊN LÝ MỐI LIÊN HỆ PHỔ BIẾN VÀO VIỆC PHÁT HUY VAI TRÒ CỦA CÁC LỰC LƯỢNG THAM GIA PHÒNG CHỐNG DỊCH COVID-19 Ở THÀNH PHỐ HỒ CHÍ MINH HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "NGUỒN GỐC, BẢN CHẤT VÀ KẾT CẤU CỦA Ý THỨC. LIÊN HỆ TÍNH SÁNG TẠO CỦA Ý THỨC TRONG BẢO VỆ MÔI TRƯỜNG Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "THỰC TIỄN VÀ VAI TRÒ CỦA THỰC TIỄN ĐỐI VỚI NHẬN THỨC. VẬN DỤNG NGUYÊN TẮC THỰC TIỄN LÀ TIÊU CHUẨN CỦA CHÂN LÝ VÀO VIỆC TÌM HIỂU QUÁ TRÌNH HỘI NHẬP KINH TẾ QUỐC TẾ Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "BAN CHÂT CUA Y THƯC. LIÊN HỆ TINH SANG TAO CUA Y THƯC TRONG BAO VỆ MÔI TRƯỜNG Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "CẶP PHẠM TRÙ NGUYÊN NHÂN – KẾT QUẢ CỦA PHÉP BIỆN CHỨNG DUY VẬT. VẬN DỤNG CẶP PHẠM TRÙ NÀY TRONG VIỆC TÌM HIỂU VẤN ĐỀ THẤT NGHIỆP CỦA SINH VIÊN HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "NGUYÊN LÝ MỐI QUAN HỆ PHỔ BIẾN VỚI HOẠT ĐỘNG NÂNG CAO HIỆU QUẢ PHÒNG CHỐNG DỊCH COVID 19 Ở VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "CẶP PHẠM TRÙ NGUYÊN NHÂN – KẾT QUẢ CỦA PHÉP BIỆN CHỨNG DUY VẬT. VẬN DỤNG CẶP PHẠM TRÙ NÀY TRONG VIỆC TÌM HIỂU VẤN ĐỀ THẤT NGHIỆP CỦA SINH VIÊN HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "VAI TRÒ CỦA KHOA HỌC VÀ CÔNG NGHỆ ĐỐI VỚI SỰ PHÁT TRIỂN CỦA LỰC LƯỢNG SẢN XUẤT Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUY LUẬT VỀ SỰ PHÙ HỢP CỦA QUAN HỆ SẢN XUẤT VỚI TRÌNH ĐỘ PHÁT TRIỂN CỦA LỰC LƯỢNG SẢN XUẤT VÀ Ý NGHĨA CỦA NÓ ĐỐI VỚI VIỆC PHÁT TRIỂN KINH TẾ THỊ TRƯỜNG ĐỊNH HƯỚNG XÃ HỘI CHỦ NGHĨA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "Quy luật thống nhất và đấu tranh giữa các mặt đối lập.Vận dụng quy luật này để giải quyết mâu thuẫn cơ bản trong quá trình phòng, chống dịch Covid-19 ở Việt Nam hiện nay.",
          price: 0,
          path: null,
        },
        {
          name: "PHÁT HUY TÍNH SÁNG TẠO CỦA Ý THỨC TRONG HOẠT ĐỘNG BẢO VỆ MÔI TRƯỜNG NƯỚC Ở THÀNH PHỐ THỦ ĐỨC HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "VẬN DỤNG QUY LUẬT CHUYỂN HÓA TỪ NHỮNG SỰ THAY ĐỔI VỀ  LƯỢNG THÀNH NHỮNG SỰ THAY ĐỔI VỀ CHẤT VÀ NGƯỢC LẠI NHẰM  NÂNG CAO CHẤT LƯỢNG ĐIỀU TRỊ BỆNH NHÂN TẠI BỆNH VIỆN ĐKKV CỦ CHI",
          price: 0,
          path: null,
        },
        {
          name: "NHỮNG SẢN PHẨM MỚI CỦA SINH VIÊN BÁCH KHOA TRONG PHÒNG CHỐNG DỊCH BỆNH COVID19 DỰA TRÊN TÍNH SÁNG TẠO CỦA Ý THỨC",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Kinh tế chính trị",
      assignments: [
        {
          name: "KINH TẾ THỊ TRƯỜNG VÀ SỰ PHÁT TRIỂN CỦA THÀNH PHẦN KINH TẾ NHÀ NƯỚC Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "KINH TẾ THỊ TRƯỜNG ĐỊNH HƯỚNG XÃ HỘI CHỦ NGHĨA VÀ SỰ PHÁT TRIỂN CỦA THÀNH PHẦN KINH TẾ CÓ VỐN ĐẦU TƯ NƯỚC NGOÀI Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA CÔNG NGHỆ SMART HOME TRONG QUÁ TRÌNH CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "HÀNG HÓA VÀ SỰ PHÁT TRIỂN CỦA NGÀNH DỆT MAY, DA GIÀY Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA DỊCH VỤ DU LỊCH VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "ĐÁNH GIÁ KẾT QUẢ CẢI CÁCH HÀNH CHÍNH CỦA TP.HCM TRONG GIAI ĐOẠN HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA CÔNG NGHỆ SMART HOME TRONG QUÁ TRÌNH CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUY LUẬT CẠNH TRANH VÀ LIÊN HỆ ĐẾN CẠNH TRANH CỦA LĨNH VỰC SẢN XUẤT CÀ PHÊ Ở NƯỚC TA",
          price: 0,
          path: null,
        },
        {
          name: "XUẤT KHẨU THANH LONG TRONG QUÁ TRÌNH HỘI NHẬP KINH TẾ QUỐC TẾ CỦA VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "TIỀN CÔNG TRONG CHỦ NGHĨA TƯ BẢN VÀ LIÊN HỆ ĐẾN VẤN ĐỀ LƯƠNG GIÁO DỤC Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUY LUẬT CUNG CẦU VÀ LIÊN HỆ ĐẾN QUAN HỆ CUNG CẦU VỀ HÀNG HÓA KHẨU TRANG Y TẾ TẠI VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "HOÀN THIỆN THỂ CHẾ KINH TẾ THỊ TRƯỜNG ĐỊNH HƯỚNG XHCN VÀ LIÊN HỆ ĐẾN SỰ HOÀN THIỆN THỂ CHẾ VỀ GIẢI QUYẾT TĂNG TRƯỞNG KINH TẾ GẮN VỚI THỰC HIỆN TIẾN BỘ VÀ CÔNG BẰNG XÃ HỘI Ở NƯỚC TA",
          price: 0,
          path: null,
        },
        {
          name: "ỨNG DỤNG AI VÀO VIỆC XÂY DỰNG CHÍNH PHỦ ĐIỆN TỬ Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA AI VỚI CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "Cuộc cách mạng công nghiệp lần thứ 4 với công nghiệp hóa, hiện đại hóa Việt Nam hiện nay",
          price: 0,
          path: null,
        },
        {
          name: "HÀNG HÓA VÀ LIÊN HỆ ĐẾN SỰ PHÁT TRIỂN CỦA ĐIỆN THOẠI Ở VIỆT NAM HIỆN NAY.",
          price: 0,
          path: null,
        },
        {
          name: "VỀ QUY LUẬT CUNG – CẦU",
          price: 0,
          path: null,
        },
        {
          name: "THỰC TRẠNG CHO VAY NẶNG LÃI Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA TRÍ TUỆ NHÂN TẠO (AI) TRONG CUỘC CÁCH MẠNG CÔNG NGHIỆP LẦN THỨ 4 VỚI CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUÁ TRÌNH HOÀN CHỈNH ĐƯỜNG LỐI CÁCH MẠNG DÂN TỘC DÂN CHỦ NHÂN DÂN TỪ CƯƠNG LĨNH CHÍNH TRỊ ĐẦU TIÊN ĐẾN CHÍNH CƯƠNG ĐẢNG LAO ĐỘNG VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "LÝ LUẬN VỀ CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA VÀ LIÊN HỆ ĐẾN SỰ PHÁT TRIỂN NGÀNH NÔNG NGHIỆP CỦA VIỆT NAM TRONG GIAI ĐOẠN 2012 – 2022",
          price: 0,
          path: null,
        },
        {
          name: "QUY LUẬT CẠNH TRANH VÀ LIÊN HỆ ĐẾN CẠNH TRANH CỦA NGÀNH ĐIỆN TỬ-VIỄN THÔNG Ở NƯỚC TA",
          price: 0,
          path: null,
        },
        {
          name: "PHÁT TRIỂN KHOA HỌC - CÔNG NGHỆ TRONG QUÁ TRÌNH CÔNG  NGHIỆP HÓA, HIỆN ĐẠI HÓA Ở VIỆT NAM.",
          price: 0,
          path: null,
        },
        {
          name: "THỊ TRƯỜNG XĂNG DẦU VIỆT NAM TRONG BỐI CẢNH XUNG ĐỘT VŨ TRANG NGA - UKRAINE",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA AI VỚI CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA  Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỰ PHÁT TRIỂN CỦA CÔNG NGHỆ BLOCKCHAIN TRONG QUÁ TRÌNH CÔNG NGHIỆP HÓA HIỆN ĐẠI HÓA Ở VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "LÝ LUẬN VỀ NỀN KINH TẾ THỊ TRƯỜNG ĐỊNH HƯỚNG XHCN VÀ LIÊN  HỆ ĐẾN SỰ PHÁT TRIỂN CỦA THÀNH PHẦN KINH TẾ TƯ NHÂN TRONG NỀN KINH TẾ THỊ TRƯỜNG ĐỊNH HƯỚNG XHCN Ở NƯỚC TA",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Chủ nghĩa xã hội khoa học",

      assignments: [
        {
          name: "Nhà nước xã hội chủ nghĩa. Liên hệ với việc thực hiện công tác quốc phòng an ninh, bảo vệ nhà nước pháp quyền xã hội chủ nghĩa ở Việt Nam hiện nay",
          price: 0,
          path: null,
        },
        {
          name: "NHÀ NƯỚC XÃ HỘI CHỦ NGHĨA. THỰC TRẠNG VÀ GIẢI PHÁP HOÀN THIỆN CHÍNH PHỦ ĐIỆN TỬ ĐÁP ỨNG YÊU CẦU XÂY DỰNG NHÀ NƯỚC PHÁP QUYỀN XÃ HỘI CHỦ NGHĨA Ở NƯỚC TA HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỨ MỆNH LỊCH SỬ CỦA GIAI CẤP CÔNG NHÂN VÀ SỨ MỆNH LỊCH SỬ CỦA GIAI CẤP CÔNG NHÂN VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ DÂN TỘC TRONG THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI VÀ VẤN ĐỀ DÂN TỘC TRONG CÔNG CUỘC ĐỔI MỚI VÀ PHÁT TRIỂN CÁC TỈNH TÂY NGUYÊN Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUAN ĐIỂM CỦA CHỦ NGHĨA MÁC – LÊNIN VỀ THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI VÀ THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI Ở VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "THỜI KÌ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI Ở VIỆT NAM & NỀN KINH TẾ THỊ TRƯỜNG ĐỊNH HƯỚNG XÃ HỘI CHỦ NGHĨA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "Vấn đề gia đình trong thời kì quá độ lên XHCN, thục trạng và giải pháp xây dựng phát triên xây dựng Việt Nam hiện nay ",
          price: 0,
          path: null,
        },
        {
          name: "DÂN CHỦ XÃ HỘI CHỦ NGHĨA. THỰC TRẠNG VÀ GIẢI PHÁP PHÁT HUY NỀN DÂN CHỦ XÃ HỘI CHỦ NGHĨA, ĐẢM BẢO QUYỀN LÀM CHỦ CỦA NHÂN DÂN Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ GIA ĐÌNH TRONG THỜI KÌ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI . GIÁO DỤC GIỚI TÍNH TRONG GIA ĐÌNH VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ DÂN TỘC TRONG THỜI KÌ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. LIÊN HỆ VỚI QUAN HỆ NGOẠI GIAO GIỮA VIỆT NAM VỚI TRUNG QUỐC TRONG THỜI KỲ DỊCH COVID 19",
          price: 0,
          path: null,
        },
        {
          name: "GIA ĐÌNH TRONG THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. THỰC TRẠNG VÀ GIẢI PHÁP XÂY DỰNG GIA ĐÌNH VĂN HOÁ Ở NƯỚC TA HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUAN ĐIỂM CỦA CHỦ NGHĨA MÁC - LÊNIN VỀ THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. THỰC TRẠNG VÀ GIẢI PHÁP XÂY DỰNG CHỦ NGHĨA XÃ HỘI Ở VIỆT NAM HIỆN NAY TRONG VĂN KIỆN ĐẠI HỘI XIII CỦA ĐẢNG CỘNG SẢN VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ DÂN TỘC TRONG THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. LIÊN HỆ VỚI VIỆC GIỮ GÌN VÀ PHÁT HUY BẢN SẮC VĂN HÓA DÂN TỘC",
          price: 0,
          path: null,
        },
        {
          name: "DÂN CHỦ VÀ DÂN CHỦ XÃ HỘI CHỦ NGHĨA. LIÊN HỆ ĐẾN VIỆC THỰC HIỆN DÂN CHỦ TRONG CÁC LĨNH VỰC KINH TẾ ",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ DÂN TỘC TRONG THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. LIÊN  HỆ VỚI VIỆC GIỮ GÌN VÀ PHÁT HUY KHỐI ĐẠI ĐOÀN KẾT DÂN TỘC Ở VIỆT  NAM HIỆN",
          price: 0,
          path: null,
        },
        {
          name: "SỨ MỆNH LỊCH SỬ CỦA GIAI CẤP CÔNG NHÂN. LIÊN HỆ ĐẾN VIỆC  XÂY DỰNG GIAI CẤP CÔNG NHÂN VIỆT NAM TRONG SỰ NGHIỆP  CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "Quan điểm của chủ nghĩa Mác - Lênin về thời kỳ quá độ lên chủ nghĩa xã hội. Vấn đề kinh tế nhiều thành phần trong thời kỳ quá độ lên chủ nghĩa xã hội ở Việt Nam",
          price: 0,
          path: null,
        },
        {
          name: "QUAN ĐIỂM CỦA CHỦ NGHĨA MÁC- LÊNIN VỀ THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. LIÊN HỆ VỚI NHIỆM VỤ CỦA THỜI LỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI Ở VIỆT NAM.",
          price: 0,
          path: null,
        },
        {
          name: "NHÀ NƯỚC XÃ HỘI CHỦ NGHĨA. LIÊN HỆ VỚI VIỆC XÂY DỰNG ĐỘI NGŨ CÁN BỘ, CÔNG CHỨC TRONG BỘ MÁY NHÀ NƯỚC XHCN TRONG SỰ NGHIỆP ĐỔI MỚI Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "Sứ mệnh lịch sử của giai cấp công nhân. Liên hệ đến việc phát huy vai trò của giai cấp công nhân Việt Nam trong sự nghiệp công nghiệp hóa, hiện đại hóa đất nước",
          price: 0,
          path: null,
        },
        {
          name: "Quan điểm của chủ nghĩa Mac - Lênin về thời kỳ quá độ lên chủ nghĩa xã hội. Vấn đề kinh tế tư nhân trong thời kỳ quá độ lên chủ ngh",
          price: 0,
          path: null,
        },
        {
          name: "DÂN CHỦ VÀ DÂN CHỦ XÃ HỘI CHỦ NGHĨA. LIÊN HỆ ĐẾN VIỆC  THỰC HIỆN DÂN CHỦ TRONG LĨNH VỰC KINH TẾ TRONG THỜI KỲ QUÁ ĐỘ LÊN CNXH Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "NHÀ NƯỚC XÃ HỘI CHỦ NGHĨA. LIÊN HỆ VỚI VIỆC XÂY DỰNG  ĐỘI NGŨ CÁN BỘ, CÔNG CHỨC TRONG BỘ MÁY NHÀ NƯỚC TRONG SỰ NGHIỆP ĐỔI MỚI Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "SỨ MỆNH LỊCH SỬ CỦA GIAI CẤP CÔNG NHÂN. LIÊN HỆ ĐẾN VIỆC XÂY DỰNG GIAI CẤP CÔNG NHÂN VIỆT NAM TRONG SỰ NGHIỆP CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA ĐẤT NƯỚC",
          price: 0,
          path: null,
        },
        {
          name: "DÂN CHỦ VÀ DÂN CHỦ XÃ HỘI CHỦ NGHĨA. ĐÁNH GIÁ VIỆC THỰC HIỆN DÂN CHỦ TRONG LĨNH VỰC KINH TẾ TRONG THỜI KÌ QUÁ ĐỘ LÊN XÃ HỘI CHỦ NGHĨA Ở VIỆT NAM HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUAN ĐIỂM CỦA CHỦ NGHĨA MÁC – LÊNIN VỀ THỜI KỲ QUÁ ĐỘ LÊN CHỦ NGHĨA XÃ HỘI. THỰC TRẠNG VÀ GIẢI PHÁP XÂY DỰNG CHỦ NGHĨA XÃ HỘI Ở VIỆT NAM HIỆN NAY TRONG VĂN KIỆN ĐẠI HỘI XIII CỦA ĐẢNG CỘNG SẢN VIỆT NAM",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Lịch sử đảng",
      assignments: [
        {
          name: "ĐẢNG CỘNG SẢN VIỆT NAM RA ĐỜI – SỰ SÀNG LỌC NGHIÊM KHẮC CỦA LỊCH SỬ DÂN TỘC VIỆT NAM TRONG THỜI ĐẠI MỚI",
          price: 0,
          path: null,
        },
        {
          name: "QUÁ TRÌNH HOÀN CHỈNH ĐƯỜNG LỐI CÁCH MẠNG DÂN TỘC DÂN CHỦ NHÂN DÂN TỪ CƯƠNG LĨNH CHÍNH TRỊ ĐẦU TIÊN ĐẾN CHÍNH CƯƠNG ĐẢNG LAO ĐỘNG VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "VAI TRÒ CỦA NGUYỄN ÁI QUỐC ĐỐI VỚI SỰ RA ĐỜI CỦA ĐẢNG CỘNG SẢN VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "ĐẢNG LÃNH ĐẠO THỰC HIỆN ĐỐI NGOẠI, HỘI NHẬP QUỐC TẾ VÀ BIỆN PHÁP BẢO VỆ CHỦ QUYỀN BIỂN, ĐẢO CỦA TỔ QUỐC HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "QUÁ TRÌNH HÌNH THÀNH VÀ PHÁT TRIỂN ĐƯỜNG LỐI CÁCH MẠNG GIẢI PHÓNG DÂN TỘC CỦA ĐẢNG TỪ NĂM 1930 ĐẾN NĂM 1945",
          price: 0,
          path: null,
        },
        {
          name: "SỨ MỆNH LỊCH SỬ CỦA GIAI CẤP CÔNG NHÂN. LIÊN HỆ ĐẾN VIỆC PHÁT HUY VAI TRÒ CỦA GIAI CẤP CÔNG NHÂN VIỆT NAM TRONG SỰ NGHIỆP CÔNG NGHIỆP HÓA, HIỆN ĐẠI HÓA ĐẤT NƯỚC",
          price: 0,
          path: null,
        },
        {
          name: "QUÁ TRÌNH PHÁT TRIỂN ĐƯỜNG ĐỔI MỚI XÂY DỰNG ĐẤT NƯỚC CỦA ĐẢNG CỘNG SẢN VIỆT NAM THÔNG QUA ĐẠI HỘI ĐẠI BIỂU TOÀN QUỐC LẦN THỨ VIII VÀ LẦN THỨ IX",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Tư tưởng Hồ Chí Minh",
      assignments: [
        {
          name: "TƯ TƯỞNG HỒ CHÍ MINH VỀ ĐẠI ĐOÀN KẾT DÂN TỘC",
          price: 0,
          path: null,
        },
        {
          name: "TƯ TƯỞNG HỒ CHÍ MINH VỀ CON NGƯỜI",
          price: 0,
          path: null,
        },
        {
          name: "VẬN DỤNG TƯ TƯỞNG HỒ CHÍ MINH VỀ CÁCH MẠNG GIẢI PHÓNG DÂN TỘC VÀO GIÁO DỤC TINH THẦN YÊU NƯỚC CHO SINH VIÊN TRƯỜNG ĐẠI HỌC BÁCH KHOA - ĐHQG.HCM TRONG GIAI ĐOẠN HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "GIÁ TRỊ TƯ TƯỞNG HỒ CHÍ MINH ĐỐI VỚI DÂN  TỘC VÀ THỜI ĐẠI. LIÊN HỆ ĐẾN VAI TRÒ CỦA VIỆC HỌC  TẬP MÔN HỌC TƯ TƯỞNG HỒ CHÍ MINH ĐỐI VỚI SINH VIÊN",
          price: 0,
          path: null,
        },

        {
          name: "CƠ SỞ HÌNH THÀNH TƯ TƯỞNG HỒ CHÍ MINH. LIÊN HỆ ĐẾN VIỆC CHỌN LỌC, TIẾP THU NHỮNG GIÁ TRỊ TỐT ĐẸP CỦA DÂN TỘC VÀ NHÂN LOẠI ĐỂ TỪ ĐÓ HÌNH THÀNH NĂNG LỰC,PHẨM CHẤT CỐT LÕI CỦA NGƯỜI SINH VIÊN VIỆT NAM TRONG THẾ KỶ XXI",
          price: 0,
          path: null,
        },
        {
          name: "TƯ TƯỞNG HỒ CHÍ MINH VỀ ĐẠO ĐỨC. LIÊN HỆ ĐẾN SINH VIÊN HỌC TẬP VÀ LÀM THEO TƯ ĐƯỞNG, ĐẠO ĐỨC HỒ CHÍ MINH HIỆN NAY",
          price: 0,
          path: null,
        },
        {
          name: "VẬN DỤNG TƯ TƯỞNG HỒ CHÍ MINH VỀ NHÀ NƯỚC DÂN CHỦ VÀO XÂY DỰNG Ý THỨC DÂN CHỦ CHO SINH VIÊN TRƯỜNG ĐẠI HỌC BÁCH KHOA – ĐHQG. HCM TRONG GIAI ĐOẠN HIỆN NAY",
          price: 0,
          path: null,
        },
      ],
    },
  ];
  const DataAssignmentFreedom = [
    {
      name: "Con người và môi trường",

      assignments: [
        {
          name: "Rác thải điện tử",
          price: 0,
          path: null,
        },
        {
          name: "CÁC TẦNG KHÍ QUYỂN VÀ VAI TRÒ ĐỐI VỚI CON NGƯỜI VÀ SINH VẬT",
          price: 0,
          path: null,
        },
        {
          name: "NGÀY TRÁI ĐẤT VƯỢT NGƯỠNG",
          price: 0,
          path: null,
        },
        {
          name: "PHÂN TÍCH 5 HIỆN TƯỢNG TƯƠNG TÁC GIỮA",
          price: 0,
          path: null,
        },
        {
          name: "TÍNH KHOA HỌC LAO ĐỘNG TRONG CÁC SẢN PHẨM GIA DỤNG VÀ CÔNG NGHIỆP",
          price: 0,
          path: null,
        },
        {
          name: "Tình trạng ô nhiễm không khí tại Tp HCM",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ RÁC THẢI VÀ XỬ LÝ RÁC THẢI HÀNG NGÀY",
          price: 0,
          path: null,
        },
        {
          name: "VẬT LIỆU XANH Tấm Nhôm Aluminium ",
          price: 0,
          path: null,
        },
        {
          name: "TÁC ĐỘNG CỦA VIỆC GIA TĂNG DÂN SỐ ĐẾN MÔI TRƯỜNG",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Khởi nghiệp",
      assignments: [
        {
          name: "Dự án xây dựng hệ thống Luxury Homestay",
          price: 0,
          path: null,
        },
        {
          name: "MÔ HÌNH NHÀ LƯỚI TRỒNG RAU SẠCH",
          price: 0,
          path: null,
        },
        {
          name: "MÔ HÌNH XE BÁN BÁNH VÀ CAFÉ DI ĐỘNG",
          price: 0,
          path: null,
        },
        {
          name: "Study coffee",
          price: 0,
          path: null,
        },
        {
          name: "2001 FOOD TRUCK",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Kinh tế học đại cương",
      assignments: [
        {
          name: "Phân tích chính sách kinh tế vĩ mô của Mỹ giai đoạn từ năm 2000 đến nay",
          price: 0,
          path: null,
        },
        {
          name: "CHÍNH SÁCH TIỀN TỆ VIỆT NAM GIAI ĐOẠN ĐẠI DỊCH COVID-19 BỨC TRANH KINH TẾ CHUNG VÀ CƠ CẤU GDP CỦA VIỆT NAM",
          price: 0,
          path: null,
        },
        {
          name: "SO SÁNH TÌNH HÌNH KINH TẾ VĨ MÔ GIỮA VIỆT NAM VÀ NIGERIA (QUỐC GIA BỊ COVID BỎ QUÊN ) GIAI ĐOẠN 2020 – 2021",
          price: 0,
          path: null,
        },
        {
          name: "Tình hình lạm phát và tăng trưởng kinh tế của Việt Nam giai đoạn cuối năm 2020-2021",
          price: 0,
          path: null,
        },
        {
          name: "VẤN ĐỀ THẤT NGHIỆP TẠI VIỆT NAM GIAI ĐOẠN 2010 – 2020 THỊ TRƯỜNG LAO ĐỘNG VIỆT NAM DƯỚI TÁC ĐỘNG CỦA ĐẠI DỊCH COVID – 19",
          price: 0,
          path: null,
        },
        {
          name: "PHÂN TÍCH TÌNH HÌNH CUNG, CẦU, CÂN BẰNG THỊ TRƯỜNG SỮA VINAMILK TẠI VIỆT NAM VÀ ĐỀ XUẤT GIẢI PHÁP",
          price: 0,
          path: null,
        },
        {
          name: "PHÂN TÍCH HÀNH VI CỦA NGƯỜI TIÊU DÙNG SỬ DỤNG  SẢN PHẨM SỮA TH TRUE MILK TRÊN THỊ TRƯỜNG HIỆN NAY THỊ TRƯỜNG LAO ĐỘNG VIỆT NAM DƯỚI TÁC ĐỘNG CỦA ĐẠI DỊCH COVID – 19",

          price: 0,
          path: null,
        },
        {
          name: "PHÂN TÍCH HÀNH VI TIÊU DÙNG CỦA KHÁCH HÀNG TẠI CÁC CỬA HÀNG TIỆN LỢI trên HCM",
          price: 0,
          path: null,
        },
        {
          name: "CUNG, CẦU, GIÁ CẢ VÀ CÁC CHÍNH SÁCH TÁC ĐỘNG ĐẾN THỊ TRƯỜNG LÚA, GẠO VIỆT NAM GIAI ĐOẠN 2018 - 2022",
          price: 0,
          path: null,
        },
        {
          name: "Vấn đề tiêu thụ điện ở Việt Nam",
          price: 0,
          path: null,
        },
        {
          name: "Các yếu tố ảnh hưởng đến mức tiêu thụ của công ty Toyota",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Kinh tế kỹ thuật",
      assignments: [
        {
          name: "BTL của thầy HÀ VĂN HIỆP (code lâu nên không viết hoa tên thầy được, thông cảm ạ)",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Quản lý dự án",
      assignments: [
        {
          name: "Xây dựng đội nhóm hiệu suất cao",
          price: 0,
          path: null,
        },
        {
          name: "LÃNH ĐẠO BẰNG CÁCH LÀM GƯƠNG",
          price: 0,
          path: null,
        },
        {
          name: "BÀI TẬP CHƯƠNG 2 ",
          price: 0,
          path: null,
        },
        {
          name: "Sách Mastering Project Management. Tac gia: James P. Lewis-2nd edition",
          price: 0,
          path: null,
        },
        {
          name: "LEADing By Example",
          price: 0,
          path: null,
        },
        {
          name: "Ứng dụng các công cụ đã học trong việc lập kế hoạch và kiểm soát dự án làm việc nhóm Quản lý dự án cho kỹ sư",
          price: 0,
          path: null,
        },
        {
          name: "Mô hình Agile",
          price: 0,
          path: null,
        },
        {
          name: "THIẾT KẾ SẢN PHẨM CÔNG TY VIỆT THÁI – THƯƠNG HIỆU HIGHLANDS COFFEE",
          price: 0,
          path: null,
        },
        {
          name: "Thực hiện quản lý dự án ",
          price: 0,
          path: null,
        },
      ],
    },
    {
      name: "Quản lý sản xuất",
      assignments: [
        {
          name: "PHÂN TÍCH QUYẾT ĐỊNH QUẢN LÝ VẬN HÀNH CỦA DOANH NGHIỆP COCA-COLA",
          price: 0,
          path: null,
        },
        {
          name: "PHÂN TÍCH QUẢN LÝ TRONG VẬN HÀNH CỦA CÔNG TY TNHH SẢN XUẤT HÀNG TIÊU DÙNG BÌNH TIÊN (BITI’S)",
          price: 0,
          path: null,
        },
        {
          name: "Amazon",
          price: 0,
          path: null,
        },
        {
          name: "Phân tích các quyết định của công ty cổ phần tập đoàn Hoa Sen",
          price: 0,
          path: null,
        },
        {
          name: "ĐIỀU ĐỘ SẢN XUẤTvCÔNG TY CỔ PHẦN MAY MẶC VIỆT TIẾN",
          price: 0,
          path: null,
        },
        {
          name: "BỐ TRÍ MẶT BẰNG CÔNG TY HONDA – ÔTÔ BIÊN HÒA",
          price: 0,
          path: null,
        },
      ],
    },
  ];

  const [statusCourseBasic, setStatusCourseBasic] = useState(false);
  const [statusCourseAdvance, setStatusCourseAdvance] = useState(false);
  const [statusCourseSociety, setStatusCourseSociety] = useState(false);
  const [statusCourseFreedom, setStatusCourseFreedom] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [active, setActive] = useState(false);
  const [text, setText] = useState(
    "Hiện tại để tránh tình trạng scam tài liệu nên các bạn ib mình để nhận trực tiếp nhé!"
  );
  const [Videos, setVideos] = useState(null);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setVideos(VideoDefault);
  }, []);
  useEffect(() => {
    document.title = "Bài tập lớn Bách Khoa";
  }, []);
  const handleResize = () => {
    window.innerWidth < 768
      ? (() => {
          setVideos(() => {
            return VideoDefault.slice(0, 4);
          });
        })()
      : (() => {
          setVideos(() => {
            return VideoDefault;
          });
        })();
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className={cx("popupModal", { active_con: isPopup })}>
        <section className={cx("section", { active: active })}>
          <div
            onClick={() => {
              setActive(false);
              setTimeout(() => {
                setIsPopup(false);
              }, 400);
            }}
            className={cx("overlay")}
          ></div>
          <div className={cx("modal-box")}>
            {
              <FontAwesomeIcon
                fontSize={"2.9rem"}
                icon={faTimeline}
              ></FontAwesomeIcon>
            }
            {/* <i className={cx("")}"fa-regular fa-circle-check"></i> */}
            <h2 className={cx("h")}>{"Cách Nhận Bài Tập lớn"}</h2>
            <h3 className={cx("h")}>{text}</h3>

            <div className={cx("buttons")}>
              <Button
                onclick={() => {
                  setActive(false);
                  setTimeout(() => {
                    setIsPopup(false);
                  }, 400);
                }}
                width
                text={"Trở lại"}
              ></Button>
              <Button
                width
                blank
                link={"https://www.facebook.com/profile.php?id=100086390823801"}
                onclick={() => {
                  setActive(false);
                  setTimeout(() => {
                    setIsPopup(false);
                  }, 400);
                }}
                text={"Liên hệ"}
              ></Button>
            </div>
          </div>
        </section>
      </div>
      <div className={cx("container")}>
        <div>
          <div className={cx("intro")}>
            <h1>Tổng Hợp Bài Tập Lớn</h1>
            <span>
              Dưới đây là một số bài tập lớn mà anh sưu tập cũng như mua về, có
              một số có trả phí nhẹ vì nếu miên phí hết các bạn sẽ sử dụng cho
              mục đích không tốt.
            </span>

            <div>
              <h2>Lưu ý sử dụng</h2>
              <div className={cx("benefits")}>
                <div className={cx("benefits-list")}>
                  <div>
                    <FontAwesomeIcon
                      className={cx("icon")}
                      icon={faCheck}
                    ></FontAwesomeIcon>
                    <span>Không được dùng để bán lại.</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className={cx("icon")}
                      icon={faCheck}
                    ></FontAwesomeIcon>
                    <span>Không nên copy y chan để nộp thầy cô.</span>
                  </div>
                </div>
                <div className={cx("benefits-list")}>
                  <div>
                    <FontAwesomeIcon
                      className={cx("icon")}
                      icon={faCheck}
                    ></FontAwesomeIcon>
                    <span>Muốn điểm cao thì đọc phải hiểu</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className={cx("icon")}
                      icon={faCheck}
                    ></FontAwesomeIcon>
                    <span>Tài liệu chỉ nên tham khảo</span>
                  </div>
                </div>
              </div>
              <div className={cx("advertisement-mobile")}>
                <div className={cx("container-advertisement")}>
                  <div className={cx("container-image")}>
                    <img className={cx("image")} src={subject}></img>
                    <h5 style={{ margin: 0 }} className={cx("h5_mobile")}>
                      Tặng BTL các môn
                    </h5>
                    <Button link={"/course/gt2"} text={"Xem thêm"}></Button>
                  </div>

                  <div className={cx("detail-advertisement")}>
                    <div>
                      <FontAwesomeIcon
                        fontSize="1.4rem"
                        color="#494949"
                        icon={faUser}
                      ></FontAwesomeIcon>
                      <span>Sinh viên Bách Khoa</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        fontSize="1.4rem"
                        color="#494949"
                        icon={faFacebook}
                      ></FontAwesomeIcon>
                      <span>Học Livestream</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        fontSize="1.4rem"
                        color="#494949"
                        icon={faWordpress}
                      ></FontAwesomeIcon>
                      <span>Hai buổi/tuần học hơn 3 tháng</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        fontSize="1.4rem"
                        color="#494949"
                        icon={faTimeline}
                      ></FontAwesomeIcon>
                      <span>Bận có thể xem lại video </span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        fontSize="1.4rem"
                        color="#494949"
                        icon={faCameraRetro}
                      ></FontAwesomeIcon>
                      <span>Hỗ trợ bài tập tớp các môn</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={cx("document")}>
                <div>
                  <h2>Bài tập lớn</h2>
                </div>
                <div>
                  <div className={cx("program")}>
                    <span>
                      Tài liệu hiện tại tất cả đều <span>miễn phí</span>, thắc
                      mắc gì thì nhắn anh
                    </span>
                    <a
                      target="_blank"
                      href={
                        "https://www.facebook.com/profile.php?id=100086390823801"
                      }
                    >
                      Liên hệ
                    </a>
                  </div>
                  <div>
                    <span className={cx("k23")}>
                      Lưu ý, các bạn <b>K23</b> muốn xem tài liệu các môn đại
                      cương thì bấm vô{" "}
                      <a href={"/home/600"}>tài liệu các khoa</a> chứ đây là bài
                      tập lớn, còn bài tập lớn là gì thì sau này học sẽ biết
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setStatusCourseBasic((state) => {
                        return !state;
                      });
                    }}
                    className={cx("basic-course", { active_popup: true })}
                  >
                    <div>
                      <FontAwesomeIcon
                        className={cx("icon")}
                        icon={faMinus}
                      ></FontAwesomeIcon>
                      <span>1. Các môn học đại cương năm nhất, năm 2</span>
                    </div>
                    <span>{DataAssignmentBasic.length} môn</span>
                  </div>

                  {statusCourseBasic && (
                    <div>
                      <div>
                        {DataAssignmentBasic?.map((ass) => {
                          return (
                            <Subject
                              fee={ass.fee}
                              onclick={setIsPopup}
                              handleActive={setActive}
                              aadNote={() => {
                                setText(
                                  ass.note ||
                                    "Để tránh bị scam tài liệu các bạn ib mình để nhận nhé"
                                );
                              }}
                              active
                              name={ass.name}
                              weight={"Giá"}
                              data={ass.assignments}
                            ></Subject>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div
                    className={cx("basic-course", { active_popup: true })}
                    onClick={() => {
                      setStatusCourseSociety((state) => {
                        return !state;
                      });
                    }}
                  >
                    <div>
                      <FontAwesomeIcon
                        className={cx("icon")}
                        icon={faMinus}
                      ></FontAwesomeIcon>
                      <span>2. Các môn chính trị, xã hội</span>
                    </div>
                    <span>{DataAssignmentSocial.length} môn</span>
                  </div>
                  <div>
                    {statusCourseSociety && (
                      <div>
                        {DataAssignmentSocial?.map((ass) => {
                          return (
                            <Subject
                              onclick={setIsPopup}
                              handleActive={setActive}
                              active
                              aadNote={() => {
                                setText(
                                  ass.note ||
                                    "Để tránh bị scam tài liệu các bạn ib  trực tiếp mình để nhận nhé"
                                );
                              }}
                              name={ass.name}
                              weight={"Giá"}
                              data={ass.assignments}
                            ></Subject>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    className={cx("basic-course", { active_popup: true })}
                    onClick={() => {
                      setStatusCourseAdvance((state) => {
                        return !state;
                      });
                    }}
                  >
                    <div>
                      <FontAwesomeIcon
                        className={cx("icon")}
                        icon={faMinus}
                      ></FontAwesomeIcon>
                      <span>3. Các môn chuyên ngành, cơ sở ngành</span>
                    </div>
                    <span>{DataAssignmentSpecialized.length} môn</span>
                  </div>
                  <div>
                    {statusCourseAdvance && (
                      <div>
                        {DataAssignmentSpecialized?.map((ass) => {
                          return (
                            <Subject
                              onclick={setIsPopup}
                              handleActive={setActive}
                              aadNote={() => {
                                setText(
                                  ass.note ||
                                    "Để tránh bị scam tài liệu các bạn ib  trực tiếp mình để nhận nhé"
                                );
                              }}
                              active
                              name={ass.name}
                              weight={"Giá"}
                              data={ass.assignments}
                            ></Subject>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    className={cx("basic-course", { active_popup: true })}
                    onClick={() => {
                      setStatusCourseFreedom((state) => {
                        return !state;
                      });
                    }}
                  >
                    <div>
                      <FontAwesomeIcon
                        className={cx("icon")}
                        icon={faMinus}
                      ></FontAwesomeIcon>
                      <span>4. Các tự chọn A,B và khác</span>
                    </div>
                    <span>{DataAssignmentFreedom.length} môn</span>
                  </div>
                  <div>
                    {statusCourseFreedom && (
                      <div>
                        {DataAssignmentFreedom?.map((ass) => {
                          return (
                            <Subject
                              aadNote={() => {
                                setText(
                                  ass.note ||
                                    "Để tránh bị scam tài liệu các bạn ib trực tiếp mình để nhận nhé"
                                );
                              }}
                              onclick={setIsPopup}
                              handleActive={setActive}
                              active
                              name={ass.name}
                              weight={"Giá"}
                              data={ass.assignments}
                            ></Subject>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx("content")}></div>
        </div>
        <div className={cx("advertisement-desktop")}>
          <div className={cx("link-intro")}>
            <h3>CÁC CHỦ ĐỀ ĐÁNG CHÚ Ý</h3>
            <div>
              <Button
                link={"/home/250"}
                pt5
                text={"Thông Lớp Đại Cương"}
              ></Button>
              <Button
                link={"https://zalo.me/g/sqzmqu522"}
                blank
                pt5
                text={"Nhóm Zalo"}
              ></Button>
              <Button link={"/home/50"} pt5 text={"Tài Liệu Các Khoa"}></Button>
              <Button
                link={"document/290"}
                pt5
                text={"Khóa học miễn phí"}
              ></Button>
            </div>
          </div>
          <div className={cx("container-advertisement")}>
            <div className={cx("container-image")}>
              <img className={cx("image")} src={subject}></img>
            </div>
            <span>Tặng kèm BTL và khóa học chuyên ngành</span>
            <Button link={"/course/gt1"} text={"Xem thêm"}></Button>
            <div className={cx("detail-advertisement")}>
              <div>
                <FontAwesomeIcon
                  fontSize="1.4rem"
                  color="#494949"
                  icon={faUser}
                ></FontAwesomeIcon>
                <span>Sinh viên Bách Khoa</span>
              </div>
              <div>
                <FontAwesomeIcon
                  fontSize="1.4rem"
                  color="#494949"
                  icon={faFacebook}
                ></FontAwesomeIcon>
                <span>Học Livestream</span>
              </div>
              <div>
                <FontAwesomeIcon
                  fontSize="1.4rem"
                  color="#494949"
                  icon={faWordpress}
                ></FontAwesomeIcon>
                <span>Hai buổi/tuần học hơn 3 tháng</span>
              </div>
              <div>
                <FontAwesomeIcon
                  fontSize="1.4rem"
                  color="#494949"
                  icon={faTimeline}
                ></FontAwesomeIcon>
                <span>Bận có thể xem lại video </span>
              </div>
              <div>
                <FontAwesomeIcon
                  fontSize="1.4rem"
                  color="#494949"
                  icon={faCameraRetro}
                ></FontAwesomeIcon>
                <span>Hỗ trợ bài tập tớp các môn</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className={cx("course-heading")}>
          <h2>
            <span>Video Hay Nên Xem</span>
          </h2>
        </div>
        <div className={cx("course-list")}>
          {Videos?.map((item, index) => {
            return (
              <div>
                <Card
                  blank={true}
                  link={item.path}
                  document={true}
                  video={true}
                  key={index}
                  title={item.name}
                  image={item.img}
                ></Card>
              </div>
            );
          })}
        </div>
        <div className={cx("pagination", { desktop: true })}>
          <Pagination
            onChange={(page) => {
              setVideos(VideoDefault?.slice(page * 4 - 4, page * 4));
            }}
            defaultCurrent={1}
            total={30}
          ></Pagination>
        </div>
      </>
    </>
  );
}

export default Assignment;
